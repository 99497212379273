<template>
    <svg  viewBox="0 0 51 79" fill="none">
        <path id="svg_1" fill="currentColor" d="m35.2617,71.165c-5.579,1.246 -10.6327,2.821 -15.1612,4.724c-1.0662,0.448 -2.2921,0.149 -3.0259,-0.727c-0.0202,-0.024 -0.0399,-0.048 -0.0594,-0.073c-0.4093,-0.526 -0.5899,-1.171 -0.5415,-1.804l-0.0014,-0.002c-0.147,-0.532 -0.1261,-1.113 0.0891,-1.66c1.5736,-3.9942 2.7856,-9.0383 3.6368,-15.1321c0.1968,-1.4089 1.4795,-2.4122 2.8936,-2.2635c0.7017,0.0739 1.3092,0.418 1.7289,0.9191c0.4262,0.5089 0.6586,1.1796 0.599,1.8936c-0.1652,1.9827 -0.3594,3.8155 -0.5827,5.4986l0.0014,0.0017c4.1377,-4.687 6.6332,-8.1761 7.4869,-10.4671c0.0275,-0.0735 0.0581,-0.146 0.092,-0.2167c2.0906,-4.374 2.8368,-9.1505 2.2386,-14.3298l-0.002,-0.0177c-0.7419,-6.833 -3.0268,-12.5349 -6.8549,-17.1057c-0.0618,-0.0739 -0.1243,-0.1476 -0.1869,-0.2209c-0.0059,-0.0069 -0.0118,-0.0138 -0.0176,-0.0207c-0.028,-0.0334 -0.055,-0.0674 -0.0812,-0.1022c-0.5318,-0.704 -1.0632,-1.3731 -1.5945,-2.0074c-4.5042,-5.3782 -8.9945,-8.2562 -13.471,-8.6342c-0.7236,-0.0611 -1.3521,-0.41 -1.7827,-0.9242c-0.4298,-0.5133 -0.6629,-1.1913 -0.5974,-1.9126c0.1309,-1.4448 1.4089,-2.5169 2.8544,-2.3948c5.9226,0.5001 11.6083,4.003 17.0568,10.5086c0.5658,0.6756 1.129,1.3835 1.6897,2.1238l0.0012,0.0014c0.0573,0.0675 0.1146,0.1352 0.1713,0.203c4.4996,5.3726 7.1783,11.9962 8.0362,19.8709c0.7083,6.1325 -0.1786,11.8246 -2.6605,17.0768l-0.0014,-0.0017c-1.2035,3.1461 -4.5808,7.7771 -10.1317,13.8931l0.0014,0.0017c2.261,-0.6687 4.614,-1.2763 7.0593,-1.8224c0.9911,-0.2215 1.9734,0.1466 2.5803,0.8713c0.2614,0.3121 0.4533,0.6906 0.5461,1.1161c0.3087,1.415 -0.5909,2.819 -2.0091,3.136z"/>
    </svg>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">

</style>